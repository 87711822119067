import * as Yup from 'yup';
export var bonusAmountValidation = Yup.object().shape({
    ranges: Yup.array()
        .of(Yup.object().shape({
        amount: Yup.number()
            .moreThan(0, 'Amount must be greater than 0')
            .required('Amount is required'),
        minSeniority: Yup.number().required('Minimum seniority is required'),
        maxSeniority: Yup.number().required('Maximum seniority is required'),
    }))
        .required('Ranges are required'),
    startDate: Yup.date().required(),
    endDate: Yup.date().required(),
});
